<template>
    <div class="list-index">
<!--        <top-menu />-->
        <div class="content">
          <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane  name="ListAbility" >
                <span slot="label"><i class="iconfont icon-yunshiyanshi"  style="vertical-align: bottom"></i> 实验室能力</span>

              </el-tab-pane>
              <el-tab-pane  name="ListLaboratory">
                <span slot="label"><i class="iconfont icon-shiyanshiguanli" style="vertical-align: bottom"></i> 实验室/机构</span>
              </el-tab-pane>
          </el-tabs>
        </div>
      <router-view ></router-view>

    </div>
</template>

<script>
import {getKeyWord, getKeyWordType, setKeyWord, setKeyWordType} from "../../utils/storageUtils";

export default {
        name: "list-index",
        data() {
            return {
                activeName: 'ListAbility',
                keyWord: '',
                keyWordType: ''
            }
        },
        methods: {
            handleClick() {
              this.$router.replace({
                name: this.activeName
              })
            },
        },
        created() {
          const keyWord = getKeyWord()
          // console.log('k', keyWord)
          if (keyWord) {
            this.keyWord = keyWord
            this.keyWordType = getKeyWordType()
            setKeyWord()
            setKeyWordType()
          }
        },
        beforeRouteEnter(to, form, next) {
            next(vm => {
              vm.activeName = to.name
            })
        },
        destroyed() {
            this.keyWord = ''
            this.keyWordType = ''
        }

    }
</script>
<style  lang="scss">
    .list-index{
        em{
            color: $danger;
            font-style: normal !important;
        }
      .el-card{
        margin: 8px 0;
      }
      .el-card__body{
        padding: 10px 20px 1px 20px;
      }
      .el-form-item{
        margin-bottom: 12px;
      }
      .el-tabs__header{
        margin-bottom: 1px;
      }
      .el-tabs__item.is-active {
        color: #333333;
        font-weight: bold;
      }
    }
</style>
<style scoped lang="scss">

    .list-index{

        .el-input, .el-cascader {
            width: 70%;
        }
        .content{
            width: 85%;
            height: 100%;
            margin: 0 auto;
            position: sticky;
            top: -20px;
            z-index: 900;
            background: #fafbfc;
        }
        .text {
            font-size: 14px;
        }

        .item {
            margin-bottom: 18px;
        }

        .clearfix:before,
        .clearfix:after {
            display: table;
            content: "";
        }
        .clearfix:after {
            clear: both
        }

        .box-card {
            /*width: 480px;*/
            margin: 15px 0;
            .search-btn{
                .el-button{
                    width: 165px;
                    padding: 9px 10px;
                }
            }
        }

        .list-card{
            height: 530px;
            overflow: auto;
            /*padding: 10px 5px;*/
            ::v-deep.el-card__body{
                padding: 0 10px;
            }
            .list-item{
                cursor: pointer;
                display: flex;
                border-radius: 2px;
                border-bottom: 1px solid $border_2;
                align-items: center;
                padding:  10px 0;
                .left{
                    display: block;
                    line-height: 1.5;
                    flex: 0.5;
                    .title{
                        font-size:16px;
                        font-family:PingFangSC-Semibold,PingFang SC;
                        font-weight:400;
                        color:rgba(51,51,51,1);
                        line-height:28px;
                    }
                    .description{
                        font-size:14px;
                        font-weight:400;
                        line-height:20px;
                        margin-top: 6px;
                        color: $font_1;
                    }
                    .search_line{
                        color: $font_2;
                        font-size: 13px;
                    }
                    .time{
                        font-size:12px;
                        font-weight:400;
                        margin-top: 6px;
                        color: $font_2
                    }

                }
                .center{
                    flex: 0.3;
                    /*margin-top: 7px;*/
                    .labels {
                        border-radius:2px;

                        div{
                            /*width: 90px;*/
                            /*border:1px solid rgba(184,180,255,1);*/
                            font-size: 14px;
                            padding: 5px;
                            color: $font_2;
                        }
                    }
                }
                .right{
                    flex: 0.2;
                    line-height: 1.4;
                    text-align: center;
                    display: block;
                    cursor: pointer;
                    p{
                        color: $danger;
                        font-size: 18px;
                        font-weight: 500;
                        margin: 5px 0;
                    }
                }
            }
        }
    }

</style>
